<template>
    <div class = "cancellation">
        <Header />
        <h1>Cancellation Policy</h1>

        <div>
            <h4>Cancellation</h4>
            <div>You may cancel a confirmed appointment up to 24 hours prior to the appointment time at no charge.</div>
            <div>A $25 cancellation fee may be applied to cancellations within 24 hours of a confirmed appointment.</div>
            <div>If you fail to cancel at least 3 hours prior to the confirmed appointment time or are absent from the service address when the technician arrives, you may be charged in full for your scheduled service.</div>
        </div>

        <div>
            <h4>Rescheduling</h4>
            <div>You may reschedule a confirmed appointment up to 3 hours prior to the appointment time at no charge.</div>
            <div>A $25 service fee will be applied to appointments rescheduled within 3 hours of the appointment time.</div>
        </div>
        
        <Footer />
    </div>
</template>

<script>
  import Header from '../components/Header.vue'
  import Footer from '../components/Footer.vue'

  export default {
    components: {
      Header,
      Footer
    },
    mounted: function(){
        fetch(this.api + "/api/recordload", { method: "POST", headers : { userdata : 'cancellation'} });
    }
  }
</script>

<style scoped>
.cancellation{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #e6e6e6;
  position: absolute;
  width: 100%;
  min-height: 100%;
  left: 0;
  top: 0;
}
</style>